import { Button, Grid, IconButton } from '@material-ui/core'
import React, { ReactElement, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Add, Close, Save } from '@material-ui/icons'
import {
  MUTATION_ADD_EDITOR_PICK,
  QUERY_GET_CONTENT_FOR_PICK,
  QUERY_GET_CONTENT_PICKED,
} from '@Api/editor_pick_api'
import { IContent } from '@Types/content_type'
import Loading from '@Components/Loading'
import isEmpty from 'lodash.isempty'
import { IListItem } from '@Types/lists_type'
import SearchForm, { FormSearchType } from '../../Content/Widget/searchForm'
import { ITopic } from '@Types/topic_type'
import clsx from 'clsx'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import { useSnackbar } from '@Hooks/useSnackbar'
import { useGlobal } from '@Hooks/useGlobal'
import CardContent from '@Components/CardPRContent'
import DragAndDrop, { DragItem, DropItem } from '@Components/DragAndDrop'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    grow: {
      flexGrow: 1,
    },
    picked: {
      marginLeft: theme.spacing(2),
    },
    divider: {
      marginInline: theme.spacing(2),
    },
    success: {
      color: theme.palette.success.main,
    },
    button_save: {
      marginRight: theme.spacing(2),
    },
  }),
)
interface DetailProps {
  topic: ITopic
}

export default function Detail({ topic }: DetailProps): ReactElement {
  const { checkPermission } = useAuth() as AuthContextType
  const [saveAddBtn, setStateSaveAddBtn] = useState<boolean>(false)
  const { onToggle } = useSnackbar()
  const { theme_name } = useGlobal()
  const MAX_CONTENT = 3
  useEffect(() => {
    const checked = checkPermission('mirror_pr_view')
    const checkedEdit = checkPermission('mirror_pr_edit')
    if (!checked) {
      window.location.href = '/'
    }

    if (checkedEdit) {
      setStateSaveAddBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  const [selected, setSelected] = useState<IContent[]>([])

  const [save] = useMutation(MUTATION_ADD_EDITOR_PICK, {
    refetchQueries: [
      {
        query: QUERY_GET_CONTENT_PICKED,
        variables: {
          listParams: {
            type: 'pr-pick',
            section: 'home',
          },
        },
      },
    ],
  })
  const { loading: loading_selected } = useQuery(QUERY_GET_CONTENT_PICKED, {
    variables: {
      listParams: {
        type: 'pr-pick',
      },
    },
    onCompleted: (data) => {
      console.log(data)
      setSelected(
        data?.CmsList?.items?.map((item: IListItem) => item.Content) ?? [],
      )
    },
    fetchPolicy: 'no-cache',
  })

  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_CONTENT_FOR_PICK,
    {
      variables: {
        contentParams: {
          order_by: [
            {
              field: 'publish_time',
              sort: 'desc',
            },
          ],
          content_type: 'pr',
        },
        page: 1,
        limit: 30,
      },
      notifyOnNetworkStatusChange: true,
    },
  )

  const handleClickSave = async () => {
    const clean_data: IListItem[] = selected.map((item) => ({
      id: item.id,
      type: 'content',
    }))
    const res = await save({
      variables: {
        type: 'pr-pick',
        section: 'home',
        items: clean_data,
      },
    })
    if (res.data?.AddList?.statusCode === 200) {
      onToggle({ type: 'success', open: true, message: 'บันทึกข้อมูลสำเร็จ!' })
    } else {
      onToggle({
        type: 'error',
        open: true,
        message: 'ไม่สามารถบันทึกข้อมูลได้!',
      })
      console.log(`LOG: cannot save latest pick ---> `, res.data.message)
    }
  }
  const handleClickAdd = (content: IContent) => {
    if (selected.findIndex((f) => f.id === content.id) !== -1) {
      onToggle({
        type: 'warning',
        open: true,
        message: 'คุณได้เลือกบทความนี้ไปแล้ว!',
      })
      return
    }
    setSelected([...selected, content])
  }

  const handleClickRemove = (content: IContent) => {
    const new_arr = selected.filter((item) => item.id !== content.id)
    setSelected([...new_arr])
  }

  const handleSearchSubmit = async (form_search: FormSearchType) => {
    await refetch({
      contentParams: {
        ...form_search,
        order_by: [
          {
            field: 'publish_time',
            sort: 'desc',
          },
        ],
        content_type: 'pr',
      },
      page: 1,
      limit: 30,
    })
  }

  if (error) return <>error :(</>
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Grid container>
            <Grid item>
              <Typography className={classes.picked} variant="h6">
                เลือกแล้ว
              </Typography>
            </Grid>
            <div className={classes.grow} />
            {saveAddBtn ? (
              <Grid item>
                <Button
                  onClick={handleClickSave}
                  // className={classes.button_save}
                  className='btn-save'
                  size="small"
                  startIcon={
                    <Save
                      color={
                        selected.length > MAX_CONTENT ? 'disabled' : undefined
                      }
                      className={clsx(
                        selected.length <= MAX_CONTENT && classes.success,
                      )}
                    />
                  }
                  disabled={selected.length > MAX_CONTENT}
                >
                  บันทึก
                </Button>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Divider variant="fullWidth" className={classes.divider} />
          <DragAndDrop
            data={selected}
            onChange={(new_data) => {
              setSelected(new_data)
            }}
          >
            <List className={classes.root} dense>
              <DropItem droppableId="droppable">
                {loading_selected ? (
                  <Loading />
                ) : (
                  !isEmpty(selected) &&
                  selected.map((item: IContent, index: number) => (
                    <DragItem
                      index={index}
                      draggableId={item.id || `draggableId_${index}`}
                      key={item._id}
                    >
                      <CardContent
                        content={item}
                        key={`${topic.value}_${item?._id}`}
                        action={() =>
                          saveAddBtn && (
                            <IconButton
                              onClick={() => handleClickRemove(item)}
                              size="small"
                            >
                              <Close color="error" />
                            </IconButton>
                          )
                        }
                      />
                    </DragItem>
                  ))
                )}
              </DropItem>
            </List>
          </DragAndDrop>
          <Typography variant="body2" color="textSecondary">
            {`หมายเหตุ: ใส่ได้สูงสุด ${MAX_CONTENT} บทความ`}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={1}></Grid>
        <Grid item xs={12} lg={7}>
          <Grid container xs={12}>
            <Grid item xs={3}>
              <Typography className={classes.picked} variant="h6">
                บทความ
              </Typography>
            </Grid>
            {/* <div className={classes.grow} /> */}
            <Grid item>
              <SearchForm
                onSubmit={handleSearchSubmit}
                display={{
                  status: false,
                }}
              />
            </Grid>
          </Grid>

          <List className={classes.root} dense>
            {loading ? (
              <Loading />
            ) : (
              <>
                {!isEmpty(data?.ContentForPick) &&
                  data?.ContentForPick?.map((item: IContent, index: number) => (
                    <CardContent
                      content={item}
                      key={`${topic.value}_${item?._id}`}
                      action={() =>
                        saveAddBtn && (
                          <IconButton size="small">
                            <Add
                              onClick={() => handleClickAdd(item)}
                              className={classes.success}
                            />
                          </IconButton>
                        )
                      }
                    />
                  ))}
              </>
            )}
          </List>
        </Grid>
      </Grid>
    </>
  )
}
